<div class="introduction">
    <div class="background">
        <img src="./assets/img/backgrounds/Vector.png" alt="">
    </div>

    <div class="content-left">
        <div class="background-shadow">
            <img src="./assets/img/backgrounds/shadow_introduction.png" class="background-shadow" alt="">
        </div>
        <img class="intro-pic" src="./assets/img/intro-pic.png" alt="">
    </div>
    <div class="content-right">
        <div class="d-flex">
            <div class="headline-rotated">
                <span>
                    I am
                </span>
            </div>
            <div class="headline-box">
                <div>
                    <span>Kaser Mahmood</span>
                    <p>FULL-STACK DEVELOPER</p>
                </div>
            </div>
        </div>

        <div class="btn-container">
            <a href="/#contact" class="btn" mat-raised-button>Let's talk!</a>
        </div>
    </div>


</div>

<div class="cotacts-links-box">
    <div class="border"></div>
    <div class="contacts-icon-box">
        <a href="https://github.com/KasZaim" target="_blank">
            <img src="./assets/img/github.png" alt="GitHub">
        </a>
        <a href="mailto:kaser@live.de">
            <img src="./assets/img/email.png" alt="">
        </a>
        <a href="https://linkedin.com/in/kaser-mahmood-6711572a1" target="_blank">
            <img src="./assets/img/linkedin.png" alt="">
        </a>
    </div>
    <span>
        Kaser&#64;live.de
    </span>
</div>

<div class="scroll-down-text">
    <span>
        Scroll down
    </span>
    <img src="./assets/img/arrow.png" alt="">
</div>