<div class="imprint-content">
    <img src="./assets/img/close.png" routerLink="" alt="">
    
    <div class="overdiv">
        <div>
            <h1>Legal Notice</h1>

            <h2>Information according to § 5 TMG</h2>
            <p>Kaser Mahmood<br />
            Reventlowstr. 10A<br />
            25335 Elmshorn</p>
        
            <h2>Contact</h2>
            <p>Phone: 017621612264</p>
            <p>Email: Kaser&#64;live.de</p>
        
            <p>Source: <a href="https://www.e-recht24.de">eRecht24</a></p>
        </div>
        <div>
            <h1>Privacy Policy</h1>
            <h2>1. An Overview of Data Protection</h2>
            <h3>General Information</h3> 
            <p>The following notes provide a simple overview of what happens to your personal data when you visit our website. Personal data are all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our privacy policy listed below this text.</p>
            <h3>Data Collection on Our Website</h3> 
            <h4>Who is responsible for the data collection on this website?</h4> 
            <p>The data processing on this website is carried out by the website operator. Their contact details can be found in the section “Information on the Responsible Party” in this privacy policy.</p> 
            <h4>How do we collect your data?</h4> 
            <p>Your data are collected when you provide it to us. This could, for example, be data you enter on a contact form. Other data are collected automatically or after your consent when you visit the website by our IT systems. These are mainly technical data (e.g., internet browser, operating system, or time of the page view). The collection of this data is automatic as soon as you enter this website.</p> 
            <h4>What do we use your data for?</h4> 
            <p>Part of the data is collected to ensure the proper functioning of the website. Other data can be used to analyze your user behavior.</p> 
            <h4>What rights do you have regarding your data?</h4> 
            <p>You always have the right to receive information about the origin, recipient, and purpose of your stored personal data at no charge. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you may revoke this consent at any time for the future. You also have the right to request that the processing of your personal data be restricted under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.</p> 
            <p>For this purpose, as well as for further questions on the subject of data protection, you can always contact us.</p>
        
            <h2>2. Hosting</h2>
            <p>We host the content of our website with the following provider:</p>
            <h3>All-Inkl</h3> 
            <p>The provider is ALL-INKL.COM - Neue Medien Münnich, owner René Münnich, Hauptstraße 68, 02742 Friedersdorf (hereinafter All-Inkl). For details, please see the privacy policy of All-Inkl: <a href="https://all-inkl.com/datenschutzinformationen/" target="_blank" rel="noopener noreferrer">https://all-inkl.com/datenschutzinformationen/</a>.</p> 
            <p>The use of All-Inkl is based on Art. 6 para. 1 lit. f DSGVO. We have a legitimate interest in the most reliable presentation of our website. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's end device (e.g., device fingerprinting) within the meaning of the TTDSG. The consent can be revoked at any time.</p>
        
            <h4>Order Processing</h4> 
            <p>We have concluded a contract for order processing (AVV) for the use of the above-mentioned service. This is a data protection contract that ensures that this processes the personal data of our website visitors only according to our instructions and in compliance with the DSGVO.</p>
            <h2>3. General Notes and Mandatory Information</h2>
            <h3>Data Protection</h3> > <div>
                <p>The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy.</p>
                <p>When you use this website, various personal data are collected. Personal data are data with which you can be personally identified. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this happens.</p>
                <p>We would like to point out that data transmission over the Internet (e.g., communication by email) can have security gaps. A complete protection of data against access by third parties is not possible.</p>
            
                <h3>Note on the responsible entity</h3>
                <p>The responsible entity for data processing on this website is:</p>
                <p>[Full name or complete company name of the website operator and the complete address]</p>
                <p>Phone: [Phone number of the responsible entity]<br />
                Email: [Email address of the responsible entity]</p>
                <p>The responsible entity is the natural or legal person who alone or jointly with others decides on the purposes and means of processing personal data (e.g., names, email addresses, etc.).</p>
            
                <h3>Storage duration</h3>
                <p>Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the purpose for data processing no longer applies. If you assert a legitimate request for deletion or revoke consent to data processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e.g., tax or commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply.</p>
            
                <h3>General notes on the legal basis of data processing on this website</h3>
                <p>If you have consented to data processing, we process your personal data based on Art. 6 Para. 1 lit. a GDPR or Art. 9 Para. 2 lit. a GDPR if special categories of data according to Art. 9 Para. 1 GDPR are processed. In the case of explicit consent to the transfer of personal data to third countries, data processing is also based on Art. 49 Para. 1 lit. a GDPR. If you have consented to the storage of cookies or to access information in your end device (e.g., via device fingerprinting), data processing is additionally based on § 25 Para. 1 TTDSG. The consent can be revoked at any time. If your data is required for the fulfillment of a contract or for the implementation of pre-contractual measures, we process your data based on Art. 6 Para. 1 lit. b GDPR. Furthermore, we process your data if it is necessary for the fulfillment of a legal obligation based on Art. 6 Para. 1 lit. c GDPR. Data processing may also take place based on our legitimate interest according to Art. 6 Para. 1 lit. f GDPR. The respective legal bases on which processing is based are informed in the following paragraphs of this privacy policy.</p>
            
                <h3>Recipients of personal data</h3>
                <p>In the course of our business activities, we work with various external entities. In some cases, it is also necessary to transfer personal data to these external entities. We only pass on personal data to external entities if this is necessary as part of contract fulfillment, if we are legally obliged to do so (e.g., transfer of data to tax authorities), if we have a legitimate interest according to Art. 6 Para. 1 lit. f GDPR in the transfer, or if another legal basis permits the data transfer. When using processors, we only pass on personal data of our customers based on a valid contract for order processing. In the case of joint processing, a contract for joint processing is concluded.</p>
            
                <h3>Revocation of your consent to data processing</h3>
                <p>Many data processing operations are only possible with your express consent. You can revoke consent you have already given at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.</p>
            
                <h3>Right to object to data collection in special cases and to direct advertising (Art. 21 GDPR)</h3>
                <p>IF DATA PROCESSING IS CARRIED OUT ON THE BASIS OF ART. 6 PARA. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT AT ANY TIME TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING THAT OVERRIDE YOUR INTERESTS, RIGHTS, AND FREEDOMS, OR IF THE PROCESSING IS FOR THE ESTABLISHMENT, EXERCISE, OR DEFENSE OF LEGAL CLAIMS (OBJECTION UNDER ART. 21 PARA. 1 GDPR).</p>
                <p>IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT MARKETING PURPOSES, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH MARKETING; THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS RELATED TO SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT MARKETING PURPOSES (OBJECTION UNDER ART. 21 PARA. 2 GDPR).</p>
            
                <h3>Right to file a complaint with the competent supervisory authority</h3>
                <p>In the event of violations of the GDPR, those affected have a right to lodge a complaint with a supervisory authority, especially in the Member State of their habitual residence, their place of work, or the place of the alleged violation. The right to lodge a complaint is without prejudice to other administrative or judicial remedies.</p>
            
                <h3>Right to data portability</h3>
                <p>You have the right to have data that we process automatically based on your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another responsible party, this will only be done to the extent technically feasible.</p>
            
                <h3>Information, correction, and deletion</h3>
                <p>Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, their origin and recipient, and the purpose of data processing and, if necessary, a right to correction or deletion of these data. For this purpose, as well as for further questions on the subject of personal data, you can contact us at any time.</p>
            
                <h3>Right to restriction of processing</h3>
                <p>You have the right to request the restriction of the processing of your personal data. For this purpose, you can contact us at any time. The right to restrict processing exists in the following cases:</p>
                <ul>
                    <li>If you dispute the accuracy of your personal data stored with us, we usually need time to verify this. For the duration of the review, you have the right to request the restriction of the processing of your personal data.</li>
                    <li>If the processing of your personal data was/is unlawful, you can request the restriction of data processing instead of deletion.</li>
                    <li>If we no longer need your personal data, but you need them to exercise, defend, or assert legal claims, you have the right to request the restriction of the processing of your personal data instead of deletion.</li>
                    <li>If you have lodged an objection pursuant to Art. 21 Para. 1 GDPR, a balance must be struck between your interests and ours. As long as it has not yet been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data.</li>
                </ul>
                <p>If you have restricted the processing of your personal data, these data – apart from their storage – may only be processed with your consent or for the establishment, exercise, or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the European Union or a Member State.</p>
            
                <p>Source: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
            </div>
            
        
    </div>
</div>