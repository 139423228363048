<div class="portfolio-container" >
    <div class="headline-container" id="portfolio">
        <div class="headline">
            <div class="border"></div>
            <p>Portfolio</p>
            <div class="long-border"></div>
        </div>
        <span>This page is a sample of my work - please feel free to try them out.</span>
    </div>

        <div class="single-projects"  data-aos="fade-left" data-aos-duration="2000">
            <div class="projects-img">
                <img src="./assets/img/join_desktop.png" alt="">
            </div>
            <div class="projects-description " >
                <p>Join</p>
                <span class="coding-languages">JavaScript | HTML | CSS</span>
                <span>Task manager inspired by the Kanban System. Create and organize tasks using drag and drop functions, assign users and categories.</span>
                <div>
                    <a class="test-btn" target="_blank" mat-raised-button href="https://join.kaser-mahmood.com/">Live test</a>
                    <a class="test-btn github-btn" target="_blank" mat-raised-button href="https://github.com/KasZaim/Join.git">Github</a>
                </div>
            </div>
        </div>

        <div class="single-projects" [class.d-column-reverse]="isSmallScreen" data-aos="fade-right" data-aos-duration="2000">
            <div class="projects-description" style="align-items: flex-end;">
                <p>El-Pollo-Loco</p>
                <span class="coding-languages">JavaScript | HTML | CSS</span>
                <span style="padding-left: 20px;">A adventurous Jump-and-Run game based on an object-oriented approach. Help Pepe to find coins and poison bottles to fight against the killer chicken.</span>
                <div style="justify-content: flex-end;">
                    <a class="test-btn" target="_blank" mat-raised-button href="https://elpolloloco.kaser-mahmood.com/">Live test</a>
                    <a class="test-btn github-btn" target="_blank" mat-raised-button href="https://github.com/KasZaim/El-Pollo-Loco.git">Github</a>
                </div>
            </div>
            <div class="projects-img" style="justify-content: flex-start;">
                <img src="./assets/img/el-pollo-loco.png" alt="">
            </div>    
        </div>

        <div class="background">
            <img src="./assets/img/backgrounds/shadow_fifth_project.png" alt="">
        </div>


        <div class="single-projects" data-aos="fade-left" data-aos-duration="2000" style="margin-bottom: 0;">
            <div class="projects-img">
                <img src="./assets/img/pokedex.png" alt="">
            </div>
            <div class="projects-description">
                <p>Pokédex</p>
                <span class="coding-languages">JavaScript | HTML | CSS | API</span>
                <span>The Pokémon Showcase is an interactive web application for Pokémon fans, dynamically displaying Pokémon data from the PokeAPI. It features images, types, abilities, and physical stats, offering an engaging way to explore and learn about various Pokémon..</span>
                <div>
                    <a class="test-btn" target="_blank" mat-raised-button href="https://pokedex.kaser-mahmood.de/">Live test</a>
                    <a class="test-btn github-btn" target="_blank" mat-raised-button href="https://github.com/KasZaim/PokeDex.git">Github</a>
                </div>
            </div>
        </div>

</div>