<div class="description-content" id="about-me">
    <div class="background">
        <img src="./assets/img/backgrounds/Purple shadow.png" alt="">
    </div>
    <div class="description-content-left" data-aos="flip-right" data-aos-duration="2000">
        <p>About me</p>
        <span>Hello! I'm Kaser, an aspiring software developer from Elmshorn, near Hamburg. My journey in technology
            began in the transportation industry, leading me to pursue advanced training in software development at the
            Developer Academy in Munich. This experience has not only honed my technical skills, but also nurtured my
            innovative thinking.</span>

        <div class="description-content-child">
            <img src="./assets/img/bulb.png" alt="">
            <span>My background in transportation has given me a unique perspective on efficiency and logistics, which I
                now apply to software development. I'm fascinated by the potential of technology to improve lives and
                streamline processes, especially at the intersection of software development and transportation.</span>
        </div>
        <div class="description-content-child">
            <img src="./assets/img/puzzle.png" alt="">
            <span>I'm actively seeking opportunities to apply my skills in a forward-thinking team. With a passion for
                technology and a unique professional background, I'm excited to contribute to impactful projects and
                continue growing as a developer.</span>
        </div>
    </div>
    <div class="description-content-right">
        <img src="./assets/img/profilbild.png" alt="">
        <div></div>
    </div>
</div>