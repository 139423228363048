<div class="contact-section" id="contact">
    <div class="background">
        <img src="./assets/img/backgrounds/shadow_footer.png" alt="">
    </div>

    <div class="headline-contact" *ngIf="!isSubmitted">
        <div class="border"></div>
        <h1>Contact</h1>
    </div>

    <div class="contact-content">
        <div class="contact-left-content" *ngIf="!isSubmitted">
            <h2>Got a problem to solve?</h2>
            <span>
                Contact me through this form. I am interested in hearing from you, knowing your ideas and contributing to
                your projects with my work.
                <br>
                <br>
                <br>
                Need a Full Stack Developer? <b> Contact me!</b>
            </span>
        </div>

        <form [formGroup]="contactForm" *ngIf="!isSubmitted" (ngSubmit)="sendMail()">
            <div class="input-box">
                <div class="single-inputs">
                    <input type="text" formControlName="name" name="name" placeholder="Your name">
                    <img *ngIf="contactForm.get('name')?.valid" src="./assets/img/correct.png" alt="Error">
                </div>

                <div *ngIf="contactForm.get('name')?.touched && contactForm.get('name')?.invalid" class="error-msg">
                    <small>Your name is required</small>
                </div>

                <!-- Email Field -->
                <div class="single-inputs">
                    <input type="email" formControlName="email" placeholder="Your email" >
                    <img *ngIf="contactForm.get('email')?.valid" src="./assets/img/correct.png" alt="Error">
                </div>


                <div class="error-msg">
                    <small *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.invalid && !contactForm.get('email')?.value">
                        Your email is required
                    </small>
                    <small *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.value && contactForm.get('email')?.invalid">
                        Your email address must be valid
                    </small>
                </div>
            </div>

            <!-- Message Field -->
            <div class="single-inputs">
                <mat-spinner *ngIf="isLoading"></mat-spinner>
                <textarea formControlName="message" placeholder="Your message" cols="30" rows="10"
                    class="error-msg"></textarea>
                <img *ngIf="contactForm.get('message')?.valid && !isLoading" src="./assets/img/correct.png"
                    style="top: 10px;" alt="Error">
            </div>

            <div *ngIf="contactForm.get('message')?.touched && contactForm.get('message')?.invalid">
                <small>Your message is required</small>
                <!-- Bild eines roten Ausrufezeichens hier einfügen, falls gewünscht -->
            </div>

            <button [ngClass]="{'btn-disabled': !contactForm.valid}" [disabled]="isSubmitted" class="btn"
                type="submit">Send message</button>
        </form>

        <div *ngIf="isSubmitted" class="mail-success" data-aos="fade-up">
            <h2>Your message has been sent successfully</h2>
            <img src="./assets/img/mail-success.png" alt="">
            <button *ngIf="isSubmitted" class="btn" (click)="resetSubmit()" >Send New Message</button>
        </div>

    </div>

</div>