<header>
    <img routerLink="/" src="./assets/img/Kaser.png" alt="">
    
    <div class="nav-bar-Desktop">
        <a class="links" href="/#about-me">About me</a>
        <a class="links" href="/#skills">Skills</a>
        <a class="links" href="/#portfolio">Portfolio</a>
        <a class="links" href="/#contact">Contact</a>
    </div>
    <app-mobile-menu></app-mobile-menu>
</header>
