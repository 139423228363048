<div class="border"></div>
<div class="footer-content">
<div class="footer-img-container">
    <img src="./assets/img/Kaser.png" alt="">
    <a routerLink="/imprint" class="links">Imprint</a>
</div>
<span>© Kaser Mahmood 2023</span>
<div class="contacts-icon-box">
    <a href="https://github.com/KasZaim" target="_blank">
        <img src="./assets/img/github.png" alt="GitHub">
    </a>
    <a href="mailto:kaser@live.de">
        <img src="./assets/img/email.png" alt="">
    </a>
    <a href="https://linkedin.com/in/kaser-mahmood-6711572a1" target="_blank">
        <img src="./assets/img/linkedin.png" alt="">
    </a>
</div>
</div>
